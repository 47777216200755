class Elementor_Section {

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/section', ($scope) => {
            this._sticky($scope);
            this._vertical_line($scope);
        });
    }


    _sticky($scope) {
        if ($scope.hasClass('osf-sticky-active')) {
            let section_offset = $scope.offset().top,
                section_top = $('#wpadminbar').height(),
                section_height = $scope.outerHeight();
            let section_width = $scope.outerWidth();

            if ($(window).width() < 601) {
                section_top = 0;
            }
            $(window).resize(() => {
                section_width = $scope.outerWidth();
                if(!$scope.hasClass('sticky-show')){
                    section_offset = $scope.offset().top;
                }
                section_top = $('#wpadminbar').height();
                if ($(window).width() < 601) {
                    section_top = 0;
                }
            });

            $(window).scroll(() => {
                section_width = $scope.outerWidth();
                if ($(window).scrollTop() >= (section_offset + section_height - section_top)) {
                    if (!$scope.hasClass('sticky-show')) {
                        $scope.addClass('sticky-show');
                        $scope.css({
                            top: section_top,
                            width: section_width,
                            // paddingTop: 5,
                            // paddingBottom: 5
                        });
                        $("<div class='h-animate'></div>").insertAfter($scope).css({
                            height: section_height
                        });
                    }
                } else {
                    $scope.removeClass('sticky-show');
                    $scope.css({
                        top: 0,
                    });
                    if ($scope.not('.elementor-section-stretched')) {
                        $scope.css({
                            width: 'auto',
                            // paddingTop: '',
                            // paddingBottom: ''

                        })
                    }
                    let $h_animate = $scope.next('.h-animate');
                    $h_animate.remove();
                }
            });
        }
    }

    _vertical_line($scope) {
        if ($scope.hasClass('osf-vertical-line-active')) {
            $scope.find('.elementor-container').append( '<div class="vertical-lines"><div class="container-wrap"><div class="line-wrap"><div class="line-item line-1"></div><div class="line-item line-2"></div><div class="line-item line-3"></div><div class="line-item line-4"></div></div></div></div>' );
        }
    }
}

new Elementor_Section();