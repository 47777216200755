class MailchimpPopup_Floor{
    static instance;

    static getInstance() {
        if (!MailchimpPopup_Floor.instance) {
            MailchimpPopup_Floor.instance = new MailchimpPopup_Floor();
        }
        return MailchimpPopup_Floor.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-floor-plans.default', ( $scope ) => {
            $scope.find('.overlay-click').magnificPopup({
                type: 'inline',
                removalDelay: 500,
                callbacks: {
                    beforeOpen: function() {
                        this.st.mainClass = this.st.el.attr('data-effect');
                    }
                },
                midClick: true
            });
        } );
    }
}
MailchimpPopup_Floor.getInstance();
